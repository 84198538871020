window.addEventListener("DOMContentLoaded", function() {
    if(document.querySelector("#userPhone")) {
        if(screen.width > "768") {
            $.fn.setCursorPosition = function(pos) {
                if ($(this).get(0).setSelectionRange) {
                    $(this).get(0).setSelectionRange(pos, pos);
                } else if ($(this).get(0).createTextRange) {
                    var range = $(this).get(0).createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', pos);
                    range.moveStart('character', pos);
                    range.select();
                }
            };
            $('#userPhone').click(function(){
                $(this).setCursorPosition(4);
            }).mask("+7 (999) 999-99-99",{autoclear: false});
        }
    }
    
    if(document.querySelectorAll('.open-job__item_link')[0]) {
        document.querySelectorAll('.open-job__item_link').forEach((item) => {
            item.addEventListener('click', function(e) {
                e.preventDefault();
                const element = document.querySelector(this.getAttribute('href'));
                let rect = element.getBoundingClientRect().top + window.scrollY; 
                //Кол-во пикселей до элемента прибавляем к кол-ву пикселей которые пролистали(там где нажали на ссылку)
                if(screen.width <= "659") { // Проверка на то, есть ли верхний сайтбар(для моб устройств)
                    window.scroll({top: rect - 80, behavior: 'smooth'});
                } else {
                    window.scroll({top: rect - 80, behavior: 'smooth'});
                }
            });
        });
    }
  

    if(document.querySelector(".header-menu")) {
        const burgerBtn = document.querySelector(".block-hamburger");
        const menu = document.querySelector(".header-menu");
        const menuClose = document.querySelector(".header-menu__close");
    
        burgerBtn.addEventListener("click", (e) => {
            menu.classList.add("header-menu-active");
        })
    
        menuClose.addEventListener("click", (e) => {
            menu.classList.remove("header-menu-active");
        })
    }
    

    if(document.querySelector(".fp-watermark")) {
        document.querySelector(".fp-watermark").remove();
    }


    if(document.querySelector(".catalog")) {
        const catalogBtnOpen = document.querySelector(".filtersOpen");
        const catalogFilters = document.querySelector(".catalog__filters");
        const catalogCloseFilters = document.querySelector(".catalog__filters_close");
        const catalogFiltersClass = "filters-visible";

        if(window.innerWidth < 769) {
            catalogBtnOpen.addEventListener("click", () => {
                catalogFilters.classList.add(catalogFiltersClass);
            });
    
            catalogCloseFilters.addEventListener("click", () => {
                catalogFilters.classList.remove(catalogFiltersClass);
            });
        }
    }

    const footerItem = document.querySelectorAll(".footer-menu__item");

    // const footerItemLinks = document.querySelectorAll(".footer__item-links");

    // footerItemLinks.forEach(item => {
    //     expandElement(item, 'footer__item-links-active');
    // })

    footerItem.forEach(item => {
        // if(item.nextElementSibling.classList.contains("footer__item-links") && window.innerWidth < 768) {
        //     expandElement(item, 'footer__item-links-active');
        // }
        item.addEventListener("click", (e) => {
            if(window.innerWidth <= 786) {
                // console.dir(e.target);
                if(e.target && e.target.classList.contains("footer-menu__link")) {
                    e.target.nextElementSibling.classList.toggle("footer__item-links-active");
                    e.target.lastChild.previousSibling.classList.toggle("arrow-active");
                    // console.log(e.target.lastChild)
                }
                else if(e.target && e.target.parentNode.classList.contains("footer-menu__link")) {
                    e.target.classList.toggle("arrow-active");
                    e.target.parentNode.nextElementSibling.classList.toggle("footer__item-links-active");
                }
            }
                
                
                // const content = e.target.nextElementSibling;
                // expandElement(content, 'footer__item-links-active');
        })
    })


    if(document.querySelectorAll('.contacts-info__tab')) {
        const tabItems = document.querySelectorAll('.contacts-info__tab');
        const tabBtns = document.querySelectorAll('.contacts-info__btns_btn');
    
    
        tabBtns.forEach(item => {
            if(item) {
                item.addEventListener('click', () => {
                    const index = item.getAttribute('data-tab');
                    tabBtns.forEach(item => {
                        item.classList.remove('contacts-info__btns_btn-active');
                    });
                    tabItems.forEach(item => {
                        if(item.getAttribute('data-tab') === index) {
                            item.classList.add('contacts-info__tab-active');
                        } else {
                            item.classList.remove('contacts-info__tab-active');
                        }
                    });;
                    item.classList.add('contacts-info__btns_btn-active');
                });
            }
        });
    }

    const mainForm = document.querySelector('.form-standart');
    const jobForm = document.querySelector('.form-job');
    const cityForm = document.querySelector('.form-city');

	function postData(form) {
		form.addEventListener('submit', function (e) {
			e.preventDefault();

			// Сам AJAX запрос
			const request = new XMLHttpRequest();
			request.open("POST", "./mailer/smart.php");
			// Не нужно устанавливать заголовки для формата XMLHttpRequest + FormData
			// request.setRequestHeader("Content-Type", "multipart/form-data; charset=UTF-8");
			const gtg = new FormData(form);
			request.send(gtg);

			// Взаимодействие с модальным окном
			const modalRequest = document.querySelector('#modal-success'),
				  modalRequestInner = document.querySelector('.modal-success__block'),
				  modalClose = document.querySelector('.modal-success__block-close');

			modalRequest.style.cssText = "transition: .3s all ease-in-out;";


			let objRequest = {
				ok: {
					title: "Благодарю за заявку!",
					descr: "Скоро с вами свяжется мой менеджер"
				},
				bad: {
					title: "Что-то пошло не так....",
					descr: "Попробуйте ещё раз или напишите в тех.поддержку!"
				}
			};

			class MessRequest {
				constructor(message, classObj) {
					this.message = message;
					this.classObj = classObj;
				}
				render() {
					modalRequest.classList.remove("hide");
					document.body.classList.add('overflow-hidden');
					let requestMessage = document.createElement('div');
					requestMessage.className = this.classObj;
					requestMessage.innerHTML = this.message;
					modalRequestInner.appendChild(requestMessage);
					// if(modalRequestInner.childNodes[3]) {
					//     modalRequestInner.childNodes[3].nextSibling.classList.add("title");
					// }
					// console.log(modalRequestInner.childNodes[2].nextSibling);
					// console.dir(modalRequestInner.childNodes[2].nextSibling);
				}
			}

			function modalCls() {
				modalRequest.classList.add('hide');
				document.body.classList.remove('overflow-hidden');
				let objText = document.querySelector(".modal-success__block");
				while (objText.childNodes.length > 2) {
					objText.removeChild(objText.lastChild);
				}
			}
			modalClose.addEventListener('click', () => {
				modalCls();
			});
			if (modalRequest.classList.contains('hide')) {
				document.querySelector('.modal-success').addEventListener('click', function () {
					modalCls();
				});
			}

			request.addEventListener('load', () => {
				if (request.status === 200 && request.readyState === 4) {
					new MessRequest(objRequest.ok.title, "modal-success__block-title title").render();
					new MessRequest(objRequest.ok.descr, "modal-success__block-descr title").render();
				} else {
					new MessRequest(objRequest.bad.title, "modal-success__block-title title").render();
					new MessRequest(objRequest.bad.descr, "modal-success__block-descr title").render();
				}
			});

			document.querySelectorAll("form input").forEach(item => {
				item.value = "";
			});
			document.querySelectorAll("form textarea").forEach(item => {
				item.value = "";
			});
		});
	}

	if(mainForm) {
        postData(mainForm);
    }
    if(jobForm) {
        postData(jobForm);
    }
    if(cityForm) {
        postData(cityForm);
    }
});